import React from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-mux'
import 'videojs-overlay'
import 'videojs-contrib-quality-levels'
import 'videojs-max-quality-selector'
import 'videojs-overlay/dist/videojs-overlay.css'
import styles from './VideoJS.module.scss'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import airplay from '@silvermine/videojs-airplay'
import '@silvermine/videojs-airplay/dist/silvermine-videojs-airplay.css'

// /home/wright/work/LeanSquad-Web/node_modules/@silvermine/videojs-airplay/dist/silvermine-videojs-airplay.css

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import chromecast from '@silvermine/videojs-chromecast'
import '@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css'

interface Sources {
  src: string
  type: string
}

interface Props {
  options: {
    sources: Sources[]
  }
  onReady: (player: videojs.Player) => void
  title: string
}

export const VideoJS = (props: Props) => {
  const videoRef = React.useRef<HTMLDivElement>(null)
  const playerRef = React.useRef<videojs.Player | null>(null)
  const { options, onReady, title } = props

  React.useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const videoElement = document.createElement('video-js')
      videoElement.classList.add('vjs-big-play-centered', 'vjs-fluid', 'rounded-xl', 'shadow-md')
      videoRef.current.appendChild(videoElement)
      airplay(videojs)
      chromecast(videojs, { preloadWebComponents: true })

      const playerOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        techOrder: ['html5'],
        ...options,
        controlBar: {},
        plugins: {
          chromecast: {},
          airPlay: {
            addButtonToControlBar: true,
          },

          mux: {
            debug: false,
            data: {
              env_key: process.env.REACT_APP_MUX_ENV_KEY,
              player_name: 'Web Video Player',
            },
          },
        },
      }

      const player = (playerRef.current = videojs(videoElement, playerOptions, () => {
        videojs.log('player is ready')
        onReady && onReady(player)
      }))
    } else {
      const player = playerRef.current
      if (!player) {
        console.error('VideoJS: player is null')
        return
      }

      player.autoplay(false)
      player.src(options.sources)
    }
  }, [options, onReady])

  React.useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [])

  return (
    <div data-vjs-player className="w-full mx-auto ">
      <div ref={videoRef} className="w-full" />
    </div>
  )
}

export default VideoJS
