import React, { FC, ButtonHTMLAttributes, ReactNode } from 'react'

type Variant = 'primary' | 'secondary' | 'danger' | 'positive'

type ButtonProps = {
  variant?: Variant
  className?: string
  children: ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>

const variantClasses: Record<Variant, string> = {
  primary: `
    bg-gradient-to-r from-teal-400 to-teal-600 
    hover:from-teal-500 hover:to-teal-700 
    focus:ring-teal-500
    text-white shadow-md hover:shadow-lg transition-all
  `,
  secondary: `
    bg-gradient-to-r from-gray-500 to-gray-700 
    hover:from-gray-600 hover:to-gray-800 
    focus:ring-gray-600
    text-white shadow-md hover:shadow-lg transition-all
  `,
  danger: `
    bg-gradient-to-r from-red-500 to-red-700 
    hover:from-red-600 hover:to-red-800 
    focus:ring-red-500
    text-white shadow-md hover:shadow-lg transition-all
  `,
  positive: `
    bg-gradient-to-r from-emerald-400 to-emerald-600 
    hover:from-emerald-500 hover:to-emerald-700 
    focus:ring-emerald-500
    text-white shadow-md hover:shadow-lg transition-all
  `,
}

const LeanButton: FC<ButtonProps> = ({ variant = 'primary', className = '', children, ...props }) => (
  <button
    className={`inline-flex items-center justify-center rounded-md border border-transparent px-5 py-2 text-sm font-medium text-white shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${variantClasses[variant]} ${className}`}
    {...props}
  >
    {children}
  </button>
)

export default LeanButton
