import * as RuleSetValidationService from 'services/api/ruleSetValidation'
import { loadStripe } from '@stripe/stripe-js'

// create the instance of stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN || '')

export type StripePriceDetails = {
  price: string
  cycle: string
  promoId?: string
  promoPrice?: string
  promoSpecifics: string
}

const monthlyDetails = {
  price: '24.99',
  cycle: 'Monthly',
  promoSpecifics: 'Renews monthly at $24.99 USD.',
}

const legacyMonthlyDetails = {
  price: '19.99',
  cycle: 'Monthly',
  promoSpecifics: 'Renews monthly at $19.99 USD.',
}

const quarterlyDetails = {
  price: '54.99',
  cycle: 'Quarterly',
  promoSpecifics: 'Renews quarterly at $54.99 USD.',
}

const yearlyDetails = {
  price: '199.99',
  cycle: 'Annually',
  promoSpecifics: 'Renews annually at $199.99 USD.',
}

const quarterlyUpgradeDetailsPromo = {
  promoID: 'UPGRADE_TO_QUARTERLY',
  price: quarterlyDetails.price,
  promoPrice: '49.49',
  cycle: 'Quarterly',
  promoSpecifics: `Renews quarterly at $${quarterlyDetails.price} USD.`,
}

const yearlyUpgradeDetailsPromo = {
  price: yearlyDetails.price,
  promoPrice: '179.99',
  promoId: 'UPGRADE_TO_YEARLY',
  cycle: 'Annually',
  promoSpecifics: `Renews annually at $${yearlyDetails.price} USD.`,
}

const validatePromoEligibility = async (ruleSetString: string, userId: string): Promise<boolean> => {
  // For Black Friday disable regular promos
  return false

  return RuleSetValidationService.validateRuleSet(ruleSetString, userId)
}

// Document the below function
// By convention the first promo in the list is the the best promo
// This function will return the first promo that is eligible
const calculateBestPromo = async (promos: string[], userId: string): Promise<string | null> => {
  // Loop through promos
  for (const promo of promos) {
    // Validate promo eligibility
    const isEligible = await validatePromoEligibility(promo, userId)
    if (isEligible) {
      // If eligible return promoId
      return promo
    }
  }
  return null
}

// List of promos to validate eligibility sorted by priority
const monthlyPromoList: string[] = []
const quarterlyPromoList = ['UPGRADE_TO_QUARTERLY']
const yearlyPromoList = ['UPGRADE_TO_YEARLY', 'UPGRADE_QUARTERLY_TO_YEARLY']

const getDetails = (subType: 'monthly' | 'yearly' | 'quarterly' | 'UPGRADE_TO_QUARTERLY' | 'UPGRADE_TO_YEARLY' | string): StripePriceDetails => {
  switch (subType) {
    case 'monthly':
      return monthlyDetails
    case 'legacyMonthly':
      return legacyMonthlyDetails
    case 'yearly':
      return yearlyDetails
    case 'quarterly':
      return quarterlyDetails
    case 'UPGRADE_TO_QUARTERLY':
      return quarterlyUpgradeDetailsPromo
    case 'UPGRADE_QUARTERLY_TO_YEARLY':
    case 'UPGRADE_TO_YEARLY':
      return yearlyUpgradeDetailsPromo

    default:
      return monthlyDetails
  }
}

export {
  stripePromise,
  monthlyDetails,
  quarterlyDetails,
  yearlyDetails,
  getDetails,
  calculateBestPromo as calculateBestPromo,
  monthlyPromoList,
  quarterlyPromoList,
  yearlyPromoList,
}
