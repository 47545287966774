import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Box, Button, LoadingIndicator, Calendar } from 'shared/components'
import { Workout } from 'types'
import styles from './Planner.module.scss'
import { dayjs, DocumentUtils } from 'shared/functions'
import { UserChallengeContext } from 'context/UserChallengeContext'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ChallengeAPI, PlannerAPI, RecommendationsAPI } from 'services/api'
import { useHistory } from 'react-router'
import { WorkoutSection } from 'features/Workout/WorkoutSection/WorkoutSection'
import { UpdateChallengeVariables } from 'services/api/types'
import { NotyfContext } from 'context/NotyfContext'
import useScrollPosition from 'hooks/useScrollPosition'
import { isFlexChallenge } from '../../shared/functions/utils'
import { Link } from 'react-router-dom'
import { ChallengeUtils } from '../../shared/functions'
import { useUserAchievements } from 'hooks/useUserAchievements'

const today = dayjs().startOf('day')
const initialDate = today

export const Planner: React.FC = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { activeChallenge, isFetching } = userChallengeContextState
  const notyf = useContext(NotyfContext)
  const { refresh: resfreshUserAchievements } = useUserAchievements()

  const [selectedDate, setSelectedDate] = useState(initialDate)
  const [activeDate, setActiveDate] = useState(initialDate)

  const { data: plannerWorkouts, isLoading: plannerWorkoutsLoading } = useQuery(
    ['plannerWorkouts', selectedDate.startOf('month').utc().toISOString()],
    () => PlannerAPI.getPlannerWorkouts(selectedDate.startOf('month').utc().toISOString(), selectedDate.endOf('month').utc().toISOString()),
    {
      keepPreviousData: true,
      staleTime: Infinity, // becomes stale only when invalidated manually when user completes workout
    },
  )

  const isInFlexChallenge = useMemo(() => {
    return !!activeChallenge && isFlexChallenge(activeChallenge.challenge)
  }, [activeChallenge])
  const { challenge_workouts: challengeWorkouts, completed_workouts: completedWorkouts } = plannerWorkouts || {}

  const challengeReleaseDate = activeChallenge?.challenge?.releaseDate
  let challengeIsReleased = true
  if (challengeReleaseDate) {
    challengeIsReleased = ChallengeUtils.isReleased(challengeReleaseDate)
  }

  const { setScrollYStorage } = useScrollPosition(history.location.pathname, plannerWorkoutsLoading ? false : true)

  const selectedDateWorkout = challengeWorkouts?.find((w: Workout) => dayjs(w.challenge_workout?.scheduled_date).utc().isSame(selectedDate.utc(), 'day'))

  const completedWorkoutsBySelectedDate = completedWorkouts?.filter((w: Workout) => dayjs(w.completed_date).isSame(selectedDate, 'day'))

  console.log('selectedDateWorkout :>> ', selectedDateWorkout)
  console.log('completedWorkoutsBySelectedDate :>> ', completedWorkoutsBySelectedDate)

  const { data: recommendedData, isLoading: recommendedLoading } = useQuery(
    ['recommended', selectedDate.startOf('day').utc().toISOString()],
    () => RecommendationsAPI.getRecommendations('workout', selectedDate.startOf('day').utc().toISOString(), selectedDate.endOf('day').utc().toISOString()),
    {
      enabled:
        !isFetching &&
        !plannerWorkoutsLoading &&
        selectedDateWorkout === undefined &&
        !selectedDateWorkout &&
        (!completedWorkoutsBySelectedDate || completedWorkoutsBySelectedDate.length === 0),
      staleTime: Infinity, // never becomes stale, data by date shouldn't change after fetched.
    },
  )

  const { mutate: updateChallengeStartDate, isLoading: updateChallengeLoading } = useMutation(
    ({ userChallengeId, options }: UpdateChallengeVariables) => ChallengeAPI.updateUserChallenge(userChallengeId, options),
    {
      onSuccess: (res) => {
        console.log('res :>> ', res)
        notyf.success('Challenge start updated!')
        queryClient.invalidateQueries('activeChallenge')
        queryClient.invalidateQueries('todayContent')
        queryClient.invalidateQueries('plannerWorkouts')
        resfreshUserAchievements()
      },
      onError: (err: Error) => {
        notyf.error(err.message)
      },
    },
  )

  const handlePlayClick = useCallback(
    (item: Workout) => {
      setScrollYStorage(DocumentUtils.getContentDivScroll())
      if (item.challenge_workout) {
        history.push(`/workouts/${item.id}/overview?challengeWorkout=${item.challenge_workout.id}`, {
          workout: item,
        })
      } else {
        history.push(`/workouts/${item.id}/overview`, {
          workout: item,
        })
      }
    },
    [history],
  )

  const onClickViewAllWorkouts = () => {
    history.push(`/challenge/${activeChallenge?.id}/workouts`)
  }

  const renderRecommendedWorkouts = () => {
    // if challenge is flex, don't show recommended workouts
    if (isInFlexChallenge) {
      return (
        <div className="mt-8 mb-32">
          <h3 className={` font-bold text-xl`}>You are in a Flex Challenge!</h3>
          <p> You can find a FULL week&apos;s worth of workouts on the today view.</p>
          <div className="flex justify-center">
            <Link to="/today" className={styles.forgotPasswordLink}>
              <button
                className={`h-8s items-center transition-all justify-center rounded-lg border border-transparent font-bold text-lg ring-offset-2 bg-coral hover:bg-coral-dark hover:ring-slate-500 hover:ring-2 text-slate-50  px-2   shadow-sm focus:outline-none  focus:ring-white `}
              >
                Let&apos;s Go!
              </button>
            </Link>
          </div>
        </div>
      )
    }
    return renderWorkout()
  }

  const renderWorkout = useCallback(() => {
    if (isFetching || plannerWorkoutsLoading) {
      return <LoadingIndicator />
    }

    if (selectedDateWorkout) {
      if (completedWorkoutsBySelectedDate) {
        if (completedWorkoutsBySelectedDate.find((item) => item.challenge_workout?.id === selectedDateWorkout?.challenge_workout?.id)) {
          return null
        }
      }

      return <WorkoutSection workoutTitle={`WORKOUT`} finisherTitle={`FINISHER`} workout={selectedDateWorkout} handlePlayClick={handlePlayClick} />
    }

    if (recommendedData && (!completedWorkoutsBySelectedDate || completedWorkoutsBySelectedDate?.length === 0)) {
      const { workout } = recommendedData
      if (!workout) return null

      if (recommendedLoading) {
        return <LoadingIndicator />
      }
      return <WorkoutSection workoutTitle="RECOMMENDED WORKOUT" finisherTitle="RECOMMENDED FINISHER" workout={workout} handlePlayClick={handlePlayClick} />
    }
  }, [selectedDate, selectedDateWorkout, handlePlayClick, recommendedData, isFetching, recommendedLoading, plannerWorkoutsLoading, completedWorkoutsBySelectedDate])

  const onClickStartChallengeNow = () => {
    console.log(`Start Challenge Now`)
    if (activeChallenge) {
      const updateVariables: UpdateChallengeVariables = {
        userChallengeId: activeChallenge.id,
        options: {
          start_date: dayjs().startOf('day').utc().toISOString(),
        },
      }
      updateChallengeStartDate(updateVariables)
    }
  }

  let completedDates: Array<string> = []
  let scheduledDates: Array<string> = []
  if (plannerWorkouts) {
    if (completedWorkouts) {
      completedDates = completedWorkouts.reduce((result: Array<string>, curr) => {
        if (curr.completed_date) {
          result.push(curr.completed_date)
        }
        return result
      }, [])
    }
    if (challengeWorkouts) {
      scheduledDates = challengeWorkouts.reduce((result: Array<string>, curr) => {
        if (curr.challenge_workout) {
          if (activeChallenge && activeChallenge.id === curr.challenge_workout.user_challenge_id) {
            result.push(curr.challenge_workout.scheduled_date)
          }
        }
        return result
      }, [])
    }
  }

  let daysSinceStart = 0
  let progress = 0
  if (activeChallenge && activeChallenge.start_date) {
    daysSinceStart = dayjs().startOf('day').utc().diff(activeChallenge.start_date, 'days')
    progress = (Number(activeChallenge.total_completed_workouts || 0) / activeChallenge.challenge.totalWorkouts) * 100
  }

  let formattedDate = selectedDate.format('dddd, MMM DD').toUpperCase()
  if (selectedDate.isToday()) {
    formattedDate = `TODAY`
  }

  // return the number of days between two dates
  const getDaysBetweenDates = (startDate: string, endDate: string) => {
    const start = dayjs(startDate)

    const end = dayjs(endDate)

    const days = end.diff(start, 'days')

    return days
  }

  const flexDays: string[] = []
  if (activeChallenge && isInFlexChallenge) {
    const numbDays = getDaysBetweenDates(activeChallenge?.start_date, activeChallenge?.end_date)
    for (let i = 0; i <= numbDays; i++) {
      flexDays.push(dayjs(activeChallenge?.start_date).add(i, 'day').format('YYYY-MM-DD'))
    }
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Box className={styles.myProgramContainer}>
          <p className={styles.programHeader}>My Program</p>
          <p className={styles.programTitle}>{activeChallenge ? activeChallenge.challenge.title : `NO CHALLENGE STARTED`}</p>
          {activeChallenge && daysSinceStart >= 0 ? (
            <Box className={styles.challengeProgress}>
              <div className={styles.progressContainer}>
                <div className={styles.progress} style={{ width: `${progress}%` }} />
              </div>
              <p className={styles.programStartsIn}>{`${activeChallenge.total_completed_workouts} of ${activeChallenge.challenge.totalWorkouts} sessions`}</p>
            </Box>
          ) : null}
          {activeChallenge && daysSinceStart < 0 ? (
            <p className={styles.programStartsIn}>
              Challenge starts in{' '}
              <span>
                {Math.abs(daysSinceStart)} {`${Math.abs(daysSinceStart) === 1 ? 'day' : 'days'}`}!
              </span>
            </p>
          ) : null}
        </Box>
      </Box>
      <Box className={styles.content}>
        <Box className={styles.leftContent}>
          <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            activeDate={activeDate}
            setActiveDate={setActiveDate}
            completed={completedDates}
            scheduled={scheduledDates}
            flexDays={flexDays}
          />

          {activeChallenge && daysSinceStart < 0 && (
            <Box className={styles.notStartedYetContainer}>
              <Box className={styles.banner}>
                {isInFlexChallenge && (
                  <p>
                    Welcome to your planner! Your challenge will start on <span>{`${dayjs(activeChallenge.start_date).format('dddd')}`}</span>.
                  </p>
                )}
                {!isInFlexChallenge && (
                  <p>
                    Welcome to your planner! Your challenge will start on <span>{`${dayjs(activeChallenge.start_date).format('dddd')}`}</span>. In the meantime we have some
                    workouts for you to do to get you ready!
                  </p>
                )}
              </Box>
              {!isInFlexChallenge && challengeIsReleased && (
                <div className="flex flex-col space-y-2 justify-center">
                  <Button
                    classNames="h-8 mt-2 transition-all justify-center rounded-lg border border-transparent font-bold text-lg ring-offset-2  bg-coral hover:bg-coral-dark hover:ring-coral-darker hover:ring-2 text-white  px-4  shadow-sm focus:outline-none  focus:ring-white "
                    label="See Workouts"
                    onClick={onClickViewAllWorkouts}
                  />
                  <Button
                    containerStyle={styles.startChallengeNowBtn}
                    label="or start challenge now"
                    onClick={onClickStartChallengeNow}
                    loading={updateChallengeLoading}
                    loadingColor="#3da3ba"
                  />
                </div>
              )}
              {isInFlexChallenge && ChallengeUtils.isReleased(activeChallenge.challenge.releaseDate) && (
                <div className="flex justify-center">
                  <button
                    className="h-8 mt-2 transition-all justify-center rounded-lg border border-transparent font-bold text-lg ring-offset-2  bg-coral hover:bg-coral-dark hover:ring-coral-darker hover:ring-2 text-slate-50  px-4  shadow-sm focus:outline-none  focus:ring-white "
                    onClick={onClickStartChallengeNow}
                    disabled={updateChallengeLoading}
                  >
                    Start My Challenge Now!
                  </button>
                </div>
              )}
            </Box>
          )}
        </Box>
        <Box className={styles.rightContent}>
          <p className={styles.dateHeader}>{formattedDate}</p>
          {renderRecommendedWorkouts()}
          <CompletedWorkoutsSection workouts={completedWorkoutsBySelectedDate} handlePlayClick={handlePlayClick} />
        </Box>
      </Box>
    </Box>
  )
}

type CompletedWorkoutsSectionProps = {
  workouts?: Workout[]
  handlePlayClick: (item: Workout) => void
}

const CompletedWorkoutsSection: React.FC<CompletedWorkoutsSectionProps> = ({ workouts, handlePlayClick }) => {
  if (!workouts || workouts.length === 0) {
    return null
  }

  return (
    <Box className="space-y-2">
      <p className={styles.contentHeaderTitle}>COMPLETED WORKOUTS</p>
      {workouts.map((workout, index) => {
        return <WorkoutSection key={`${workout.id}-${workout.completed_date}-${index}`} workout={workout} handlePlayClick={handlePlayClick} />
      })}
    </Box>
  )
}
