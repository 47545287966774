import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query' // Import useQueryClient from react-query
import Spinner from 'react-spinkit'
import * as UCServices from '../services/api/userChallenges'
import LeanButton from './LeanButton'
import { CheckBadgeIcon, ForwardIcon } from '@heroicons/react/24/solid'
import { CheckBadgeIcon as CheckBadgeIconOutline } from '@heroicons/react/24/outline'
import { NotyfContext } from 'context/NotyfContext'
import { useContext } from 'react'
import Confetti from 'react-confetti'

const AdvanceWeek = () => {
  const notyf = useContext(NotyfContext)
  const [showConfetti, setShowConfetti] = useState(false)
  const [buttonExploded, setButtonExploded] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  // Fetch the current user challenge
  const {
    data: currentUserChallenge,
    isLoading: currentUserChallengeLoading,
    error: currentUserChallengeError,
  } = useQuery('currentUserChallenge', UCServices.getCurrentUserChallenge, {
    staleTime: Infinity,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Extract userChallengeId once it's available
  const userChallengeId = currentUserChallenge?.id

  // Determine if the subsequent queries should be enabled
  const isEnabled = Boolean(userChallengeId)

  // Check if the user can advance week
  const {
    data: canAdvanceWeekData,
    isLoading: canAdvanceWeekLoading,
    error: canAdvanceWeekError,
  } = useQuery(['canAdvanceWeek', userChallengeId], () => UCServices.canAdvanceWeek(userChallengeId!), {
    staleTime: Infinity,
    enabled: isEnabled,
  })

  // Combine loading states
  const loading = currentUserChallengeLoading || (isEnabled && canAdvanceWeekLoading)

  const shouldShowCompleteChallengeButton = canAdvanceWeekData ? canAdvanceWeekData.isNotLastWeek === false && canAdvanceWeekData.completedAllWorkouts : false

  const queryClient = useQueryClient() // Initialize queryClient

  // Handle loading state
  if (loading) {
    return <Spinner fadeIn="quarter" className="loadingIndicator" name="three-bounce" color="#FFF" />
  }
  if (!userChallengeId || !canAdvanceWeekData) {
    return null
  }

  const handleAdvanceWeek = async () => {
    const response = await UCServices.advanceWeek(userChallengeId)
    console.log('handleAdvanceWeek response :>> ', response)
    // if status 200
    if (response) {
      // invalidate the queries
      queryClient.invalidateQueries('currentUserChallenge')
      queryClient.invalidateQueries('completedChallenges')
      queryClient.invalidateQueries('activeChallenge')
      queryClient.invalidateQueries(['canAdvanceWeek', userChallengeId])
      queryClient.invalidateQueries(['currentWeek', userChallengeId])
    }
  }

  const handleCompleteChallenge = async () => {
    const response = await UCServices.finishChallenge(userChallengeId)

    if (response.message === 'Challenge marked as completed') {
      notyf.success('Challenge marked as completed!')

      setShowConfetti(true)
      setButtonExploded(true)

      setTimeout(() => {
        queryClient.invalidateQueries('currentUserChallenge')
        queryClient.invalidateQueries('completedChallenges')
        queryClient.invalidateQueries('activeChallenge')
        queryClient.invalidateQueries(['canAdvanceWeek', userChallengeId])
        queryClient.invalidateQueries(['currentWeek', userChallengeId])

        // mutate activeChallenge to null
        queryClient.setQueryData('activeChallenge', null)
        setShowConfetti(false)
        setButtonExploded(false)
      }, 5000)
    } else {
      notyf.error('Error marking challenge as complete!')
    }
  }

  if (canAdvanceWeekData.isEligible) {
    return (
      <div className=" w-full flex flex-col items-end justify-end">
        <p className=" text-lg font-medium text-gray-700"> Advance to the next week?</p>
        <LeanButton onClick={handleAdvanceWeek}>
          <ForwardIcon className="h-5 w-5 mr-2" />
          Let&apos;s Go!
        </LeanButton>
      </div>
    )

    // if they can't Advance Week but are in the final week of a challenge and they have completed all workouts
  } else if (shouldShowCompleteChallengeButton) {
    console.log(`windowWidth: ${windowWidth} windowHeight: ${windowHeight} showConfetti: ${showConfetti}`)
    return (
      <div className=" w-full flex flex-col items-end justify-end">
        {/* <Confetti width={window.innerWidth} height={window.innerHeight} /> */}
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', zIndex: 1000 }}>
          <Confetti
            run={showConfetti}
            width={windowWidth}
            height={windowHeight}
            drawShape={(ctx) => {
              // Start drawing the trophy
              ctx.beginPath()

              // Cup handles
              ctx.moveTo(-12, -10) // Left handle
              ctx.quadraticCurveTo(-20, -20, -12, -30)
              ctx.moveTo(12, -10) // Right handle
              ctx.quadraticCurveTo(20, -20, 12, -30)

              // Trophy cup
              ctx.moveTo(-10, -30)
              ctx.lineTo(-10, -10)
              ctx.lineTo(10, -10)
              ctx.lineTo(10, -30)

              // Trophy base
              ctx.moveTo(-8, -10)
              ctx.lineTo(-5, 0)
              ctx.lineTo(5, 0)
              ctx.lineTo(8, -10)

              // Rectangular base
              ctx.moveTo(-12, 0)
              ctx.lineTo(-12, 5)
              ctx.lineTo(12, 5)
              ctx.lineTo(12, 0)

              ctx.closePath()

              // Fill the trophy
              ctx.fillStyle = '#FFD700' // Gold color
              ctx.fill()

              // Outline the trophy
              ctx.strokeStyle = '#DAA520' // Darker gold outline
              ctx.stroke()
            }}
          />
        </div>
        <p className=" text-lg font-medium text-gray-700"> Ready for a new challenge?</p>

        <LeanButton onClick={handleCompleteChallenge} variant="positive" className={`transition-transform duration-500 ${buttonExploded ? 'animate-explode' : ''}`}>
          <CheckBadgeIconOutline className="h-5 w-5 mr-2" />
          {showConfetti ? 'OMG LEANTASTIC!' : 'Finish Challenge!'}
        </LeanButton>
      </div>
    )
  } else {
    return null
  }
}

AdvanceWeek.displayName = 'AdvanceWeek'

export { AdvanceWeek }
